import { APP_CART_SUBNAV_EXPERIMENT_ID, useExperiment } from '@/lib/flipper'
import { CART_ROUTE, CHECKOUT_ROUTE, CONFIRMATION_ROUTE, PRODUCT_LIST_ROUTE } from '@/routing/brandRoutes'
import { PencilBannerData } from '@/services/contentful/plp/plp'
import { PencilBanner } from '@/ui/Banner/PencilBanner/PencilBanner'
import { handleZendeskOnError } from '@/utils/helpers'
import { updateSettingZendeskClassic } from '@tkww/paper-zendesk'
import Nav from '@xo-union/tk-component-header-nav/remote'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { ComponentType, PropsWithChildren, useEffect, useState } from 'react'
import { MembershipAuthIntent, MembershipAuthIntents } from './MembershipAuthModal/MembershipAuthModal.helpers'

const MembershipAuthModal = dynamic(() => import('@/modules/core/components/Header/MembershipAuthModal'))

const product = 'paper'

export interface HeaderProps {
  loggedIn: boolean
  logOutCallback: () => void
  pencil?: PencilBannerData
}

const Header: ComponentType<PropsWithChildren<HeaderProps>> = ({ loggedIn, logOutCallback, pencil }) => {
  const [authModal, setAuthModal] = useState<MembershipAuthIntent>(null)
  const { pathname, asPath } = useRouter()
  const { isControl } = useExperiment(APP_CART_SUBNAV_EXPERIMENT_ID)
  const isPLP = pathname === PRODUCT_LIST_ROUTE
  const isCart = pathname === CART_ROUTE
  const isCheckout = pathname.includes(CHECKOUT_ROUTE) && !pathname.includes(CONFIRMATION_ROUTE)
  const isCartSubnavExperimentControl = isCart && isControl
  const isCartSubnavExperimentVariant = isCart && !isControl

  useEffect(() => {
    if (authModal) {
      updateSettingZendeskClassic('updateSettings', { zIndex: 5 }, (error) => handleZendeskOnError(error, 'header'))
    }

    return () => {
      updateSettingZendeskClassic('updateSettings', { zIndex: 9999 }, (error) => handleZendeskOnError(error, 'header'))
    }
  }, [authModal])

  return (
    <>
      {(isPLP || isCartSubnavExperimentVariant) && pencil && <PencilBanner key={asPath} {...pencil} />}
      <Nav
        activeProduct="invitations"
        analyticsProps={{ product }}
        metadata={{ applicationName: product }}
        onClickLogOut={logOutCallback}
        onClickLogIn={() => setAuthModal(MembershipAuthIntents.logIn)}
        onClickSignUp={() => setAuthModal(MembershipAuthIntents.signUp)}
        loggedIn={loggedIn}
        ssi={true}
        __renderDraftsLink__
      />
      {authModal && <MembershipAuthModal intent={authModal} setOpenModal={setAuthModal} />}
      {(isCheckout || isCartSubnavExperimentControl) && pencil && <PencilBanner {...pencil} />}
    </>
  )
}

export default Header
