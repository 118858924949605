import {
  CartViewed,
  CheckoutStarted,
  CouponApplied,
  CouponDenied,
  CouponEntered,
  CouponRemoved,
  CtaClicked,
  InformationEntered,
  LogIn,
  LogInFormDisplayed,
  MenuInteraction,
  NewRegistration,
  OrderCompleted,
  ProductAdded,
  ProductMoved,
  ProductQuicklooked,
  ProductRemoved,
  ProductsSearched,
  PromotionClicked,
  PromotionViewed,
  RegistrationFormDisplayed,
} from '@/generated/analytics'
import { getCookieByName, getOptionValueName, getProductFormatBC } from '@/utils/helpers'
import { AnalyticsUserData } from './interfaces'

import type { TrackingProps } from '@/components/Tracking/TrackingProvider/TrackingProvider'
import { OptionValue } from '@/domains/core/storefront/generated'
import { Maybe } from '@/generated/graphql/graphql'
import { createAnalyticsUrl, typewriter } from '@/modules/core/contexts/CatalogAnalytics/hooks'
import { trackConversionToImpact } from '@/modules/core/services/impact'
import { ReviewProps } from '@/services/storefront'
import { Drafts, PartialDraft } from '@/services/storefront/drafts/drafts.types'
import Honeybadger from '@honeybadger-io/js'
import { genericAnalyticsEvent } from './integrations'

const BASE_PROPERTIES = {
  selection: 'paper',
}

function trackingString(text?: string) {
  if (!text) return ''
  return text.replace(/\s/g, '_').toLowerCase()
}

function createAnalyticsUserData(): AnalyticsUserData {
  const userId = getCookieByName(window.document?.cookie, 'ajs_user_id') || 'unidentified'
  const anonymousId = getCookieByName(window.document?.cookie, 'ajs_anonymous_id') || 'unidentified'
  return {
    userId: userId.replace(/["]/g, '') ?? '',
    anonymousId: anonymousId.replace(/["]/g, '') ?? '',
  }
}

export function typewriterErrorHandler<T extends any[]>(event: (...args: T) => void, data: T) {
  try {
    event(...data)
  } catch (e: any) {
    const errorName = 'TypewriterError'
    Honeybadger.notify(e, {
      name: errorName,
      fingerprint: errorName,
      context: {
        event,
        data,
      },
      message: e?.message,
    })
  }
}

function trackCartViewed(eventData: TrackingProps & CartViewed) {
  const data = {
    ...eventData,
  }
  typewriterErrorHandler(typewriter.cartViewed, [data])
}

function trackCheckoutStarted(eventData: TrackingProps & CheckoutStarted) {
  const data = {
    ...eventData,
  }
  typewriterErrorHandler(typewriter.checkoutStarted, [data])
}

function trackLogIn(eventData?: TrackingProps & LogIn) {
  const data = {
    selection: 'save draft',
    platform: eventData?.platform || '',
    ...eventData,
  }
  typewriterErrorHandler(typewriter.logIn, [data])
}

function trackLogInFormDisplayed(eventData?: TrackingProps & LogInFormDisplayed) {
  const data = {
    platform: eventData?.platform || '',
    ...eventData,
  }
  typewriterErrorHandler(typewriter.logInFormDisplayed, [data])
}

function trackNewRegistration(eventData?: TrackingProps & NewRegistration) {
  const data = {
    selection: 'save draft',
    platform: eventData?.platform || '',
    ...eventData,
  }
  typewriterErrorHandler(typewriter.newRegistration, [data])
}

function trackRegistrationFormDisplayed(eventData?: TrackingProps & RegistrationFormDisplayed) {
  const data = {
    platform: eventData?.platform || '',
    ...eventData,
  }
  typewriterErrorHandler(typewriter.registrationFormDisplayed, [data])
}

function trackAddPlainEnvelopes(eventData: TrackingProps & Omit<ProductAdded, 'price' | 'category' | 'platform'>) {
  const data = {
    category: 'Plain Wedding Invitations Envelopes',
    price: 0,
    ...eventData,
  }
  typewriterErrorHandler(typewriter.productAdded, [data])
}

function trackCartAddPlainEnvelopes(eventData: TrackingProps & Omit<ProductAdded, 'price' | 'platform'>) {
  const data = {
    price: 0,
    location: 'cart',
    ...eventData,
  }
  typewriterErrorHandler(typewriter.productAdded, [data])
}

function trackMovetoCartFromDrafts(eventData: TrackingProps & ProductAdded) {
  const data = {
    source: 'drafts',
    ...eventData,
  }
  typewriterErrorHandler(typewriter.productAdded, [data])
}

function trackProductAdded(eventData: TrackingProps & ProductAdded) {
  typewriterErrorHandler(typewriter.productAdded, [eventData])
}

function trackProductMoved(eventData: TrackingProps & ProductMoved) {
  const data = {
    destination: 'drafts',
    source: 'cart',
    ...eventData,
  }
  typewriterErrorHandler(typewriter.productMoved, [data])
}

function trackProductDelete(eventData: TrackingProps & ProductRemoved) {
  const data = {
    location: 'cart',
    ...eventData,
  }
  typewriterErrorHandler(typewriter.productRemoved, [data])
}

const trackPromotionViewed = (eventData: TrackingProps & PromotionViewed) => {
  const data = {
    ...eventData,
  }
  typewriterErrorHandler(typewriter.promotionViewed, [data])
}

const trackProductsSearched = (eventData: TrackingProps & ProductsSearched, callback?: () => void) => {
  const data = {
    ...eventData,
    ...BASE_PROPERTIES,
  }

  return new Promise((resolve) => {
    typewriterErrorHandler(typewriter.productsSearched, [data, undefined, callback])
    resolve(true)
  })
}

const trackPromotionClicked = (eventData: TrackingProps & PromotionClicked, callback?: () => void) => {
  const data = {
    ...eventData,
  }
  return new Promise((resolve) => {
    typewriterErrorHandler(typewriter.promotionClicked, [data, undefined, callback])
    resolve(true)
  })
}

function trackShippingInfoEntered(eventData: TrackingProps & InformationEntered) {
  const data = {
    ...eventData,
  }
  typewriterErrorHandler(typewriter.informationEntered, [data])
}

function trackCouponApplied(eventData: TrackingProps & CouponApplied) {
  const data = {
    ...eventData,
    coupon_name: eventData.coupon,
  }
  typewriterErrorHandler(typewriter.couponApplied, [data])
}

function trackCouponDenied(eventData: TrackingProps & CouponDenied) {
  const data = {
    ...eventData,
    coupon_name: eventData.coupon,
  }
  typewriterErrorHandler(typewriter.couponDenied, [data])
}

function trackCouponRemoved(eventData: TrackingProps & CouponRemoved) {
  const data = {
    ...eventData,
    coupon_name: eventData.coupon,
  }
  typewriterErrorHandler(typewriter.couponRemoved, [data])
}

const trackOrderCompleted = (rawData: any, email: string, customerId: string, customerStatus: string) => {
  const { affiliation, memberId, platform, product, ...eventData } = rawData
  const revenue = +(+eventData?.subtotal_ex_tax)?.toFixed(2)
  const discount = +(+eventData?.coupon_discount)?.toFixed(2)
  const subtotal = revenue - discount
  const message: OrderCompleted = {
    products: eventData?.products
      ?.map((product: any, index: number) => {
        try {
          const metaOption = product.product_options.find((opt: any) => /metadata/i.test(opt.display_name))
          const meta = JSON.parse(metaOption.display_value)
          const optionValues = meta?.optionValues
          const url = createAnalyticsUrl(meta?.slug)
          return {
            product_id: meta?.catalogProductId,
            sku: meta?.variationId,
            category: meta?.productTypeName || '',
            name: meta?.productName,
            price: +(+product.total_ex_tax).toFixed(2),
            quantity: product.quantity,
            coupon: product?.applied_discounts?.[0]?.code || '',
            position: index + 1,
            url,
            image_url: meta?.productPhotos?.hero,
            sample: meta?.isSample,
            variant: createAnalyticsVariant(optionValues, meta.productCode),
          }
        } catch (e) {
          return null
        }
      })
      .filter(Boolean),
    coupon: eventData?.coupons?.[0]?.code || '',
    currency: eventData?.currency_code,
    order_id: eventData?.id?.toString(),
    revenue,
    discount,
    subtotal,
    shipping: +(+eventData?.shipping_cost_ex_tax)?.toFixed(2),
    tax: +(+eventData?.subtotal_tax)?.toFixed(2),
    total: +(+eventData?.total_inc_tax)?.toFixed(2),
    affiliation: affiliation as string,
    platform: platform as string,
  }
  const data = {
    ...message,
    email: email,
    affiliation: affiliation as string,
    memberId: memberId as string | null | undefined,
    platform: platform as string,
    product: product as string,
  }

  typewriterErrorHandler(typewriter.orderCompleted, [data])
  trackConversionToImpact(data, customerId, customerStatus)
}

function trackMenuInteraction(eventData: TrackingProps & MenuInteraction) {
  const data = {
    source: 'subnav',
    ...eventData,
  }
  typewriterErrorHandler(typewriter.menuInteraction, [data])
}

async function trackCtaClicked(eventData: TrackingProps & CtaClicked) {
  const eventPayload = {
    ...eventData,
  }
  typewriterErrorHandler(typewriter.ctaClicked, [eventPayload])
}

function trackCtaClickedReview(eventData: TrackingProps & CtaClicked) {
  const eventPayload = {
    element_clicked: 'link',
    location: 'review',
    reason: 'upsell',
    ...eventData,
  }

  typewriterErrorHandler(typewriter.ctaClicked, [eventPayload])
}

function trackQuickLook(eventData: TrackingProps & ProductQuicklooked) {
  const eventPayload = {
    ...eventData,
  }

  typewriterErrorHandler(typewriter.productQuicklooked, [eventPayload])
}

function trackCouponEntered(eventData: TrackingProps & CouponEntered) {
  const eventPayload = {
    ...eventData,
    coupon_name: eventData.coupon,
  }
  typewriterErrorHandler(typewriter.couponEntered, [eventPayload])
}

function trackDraftDeleted(trackingData: TrackingProps, draft: PartialDraft) {
  const { portfolioItem } = draft
  const { variation } = portfolioItem
  const eventPayload = {
    ...trackingData,
    name: variation.product.name,
    draft_id: draft.id,
    price: Number(draft.price),
    quantity: draft.quantity,
    category: variation.product.type.name,
    url: `${process.env.APP_PAPER_HOST}${process.env.APP_BASEPATH}${process.env.APP_DRAFTS_PATH}`,
    portfolio_item_id: portfolioItem?.id,
    sample: variation?.isSample,
    product_id: variation?.catalogProductId,
    sku: variation?.suiteCode,
    variant: [
      {
        color: variation?.colorThemeCode || '',
        foil_color: variation?.foilCode || '',
        format: variation?.productCode,
        material: variation?.paperTypeCode || '',
        printing_options: variation?.envelopePrintingOptionCode || '',
      },
    ],
    location: 'drafts',
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  typewriterErrorHandler(typewriter.draftDeleted, [eventPayload])
}

function trackCustomizationReviewInitialized(reviewProps: ReviewProps, trackingProps: TrackingProps) {
  const selectSegmentVariant = (reviewProps: ReviewProps) => {
    enum ProductOptionId {
      ColorTheme = 'colorThemeCode',
      FoilTheme = 'foilCode',
      PaperType = 'paperTypeCode',
      EnvelopePrintingOption = 'envelopePrintingOptionCode',
    }

    const selectProductOption = (
      productOptionId: ProductOptionId,
      optionValues: Maybe<Array<OptionValue>> | undefined
    ): OptionValue | undefined => optionValues?.find((optionValue) => optionValue.optionId === <string>productOptionId)

    return {
      color: selectProductOption(ProductOptionId.ColorTheme, reviewProps.variation.optionValues)?.name || '',
      foil_color: selectProductOption(ProductOptionId.FoilTheme, reviewProps.variation.optionValues)?.name,
      format: reviewProps.product.type.name,
      material: selectProductOption(ProductOptionId.PaperType, reviewProps.variation.optionValues)?.name || '',
      printing_options: selectProductOption(ProductOptionId.EnvelopePrintingOption, reviewProps.variation.optionValues)
        ?.valueId,
    }
  }

  const data = {
    category: reviewProps.product.type.name,
    location: 'review',
    name: reviewProps.name,
    portfolio_item_id: reviewProps.product.portfolioItemId,
    price: Number(reviewProps.price?.basePrice),
    product_id: reviewProps.variation.id,
    quantity: reviewProps.quantity,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    sample: reviewProps.variation.isSample,
    sku: reviewProps.product.type.sku,
    url: `${process.env.APP_PAPER_HOST}${process.env.APP_BASEPATH}/${reviewProps.product.slug}`,
    variant: [selectSegmentVariant(reviewProps)],
    ...trackingProps,
  }

  typewriterErrorHandler(typewriter.customizationReviewInitialized, [data])
}

function trackDraftListViewed(trackingProps: TrackingProps, drafts: Drafts) {
  const draftsTracking = drafts.map((draft, index) => {
    const { variation } = draft.portfolioItem
    return {
      image_url: createHeroImageUrl(variation.variationImages),
      name: variation.product.name,
      position: index + 1,
      price: Number(draft.price),
      product_id: variation.catalogProductId,
      quantity: draft.quantity,
      sample: variation.isSample,
      sku: variation.id,
      url: createAnalyticsUrl(variation.product.slug),
      variant: createAnalyticsVariant(variation.optionValues ?? [], variation.productCode),
    }
  })

  const data = {
    ...trackingProps,
    list_id: 'drafts',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    drafts: draftsTracking,
  }

  typewriterErrorHandler(typewriter.draftListViewed, [data])
}

const createHeroImageUrl = (variationImages: { imageUrl: string; tag: string }[]): string => {
  const heroImage = variationImages?.find((image) => {
    return image.tag === 'hero'
  })
  return heroImage ? `${process.env.APP_IMAGE_HOST}/master${heroImage.imageUrl}` : ''
}

const createAnalyticsVariant = (optionValues: OptionValue[], productCode: string) => {
  return [
    {
      color: getOptionValueName(optionValues, 'colorThemeCode') || '',
      format: getProductFormatBC(productCode),
      material: getOptionValueName(optionValues, 'paperTypeCode') || '',
      printing_options: getOptionValueName(optionValues, 'envelopePrintingOptionCode'),
      foil_color: getOptionValueName(optionValues, 'foilCode'),
      flap_type: getOptionValueName(optionValues, 'flapTypeCode'),
      paper_color: getOptionValueName(optionValues, 'paperColorCode'),
    },
  ]
}

function isMobileUserAgent(userAgent = ''): boolean {
  // Check if 'Mobi' is present in the user agent
  return /Mobi/.test(userAgent)
}

export {
  createAnalyticsUserData,
  createAnalyticsVariant,
  createHeroImageUrl,
  genericAnalyticsEvent,
  isMobileUserAgent,
  trackAddPlainEnvelopes,
  trackCartAddPlainEnvelopes,
  trackCartViewed,
  trackCheckoutStarted,
  trackCouponApplied,
  trackCouponDenied,
  trackCouponEntered,
  trackCouponRemoved,
  trackCtaClicked,
  trackCtaClickedReview,
  trackCustomizationReviewInitialized,
  trackDraftDeleted,
  trackDraftListViewed,
  trackingString,
  trackLogIn,
  trackLogInFormDisplayed,
  trackMenuInteraction,
  trackMovetoCartFromDrafts,
  trackNewRegistration,
  trackOrderCompleted,
  trackProductAdded,
  trackProductDelete,
  trackProductMoved,
  trackProductsSearched,
  trackPromotionClicked,
  trackPromotionViewed,
  trackQuickLook,
  trackRegistrationFormDisplayed,
  trackShippingInfoEntered,
}
