/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface ExperimentStarted {
	/**
	 * the name of the variant into which the user was bucketed, for that experiment

	 */
	'Experiment name': string
	'Trigger path': string
	/**
	 * the name of the experiment to which the user has been exposed

	 */
	'Variant name': string
}
export interface CtaClicked {
	category?: string
	element_clicked?: string
	location?: string
	platform?: string
	product?: string
	/**
	 * Database ID of the product

	 */
	product_id?: string
	reason?: string
}
export interface SampleDetailsItem {
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem {
	/**
	 * The color of the product

	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	paper_color?: string
	/**
	 * Which printing option did the customer specify?

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductsItem {
	/**
	 * Product Category displayed in the list

	 */
	category: string
	/**
	 * Coupon code associated with the product

	 */
	coupon?: string
	/**
	 * Image url of the product in the list

	 */
	image_url: string
	/**
	 * Product Name displayed in the list

	 */
	name: string
	/**
	 * Position of product in the list

	 */
	position: number
	/**
	 * Product Price displayed in the list

	 */
	price: number
	/**
	 * Product ID displayed in the list

	 */
	product_id: string
	/**
	 * Product quantity displayed in the list

	 */
	quantity: number
	sample_details?: SampleDetailsItem[]
	/**
	 * Sku of the product being viewed

	 */
	sku: string
	/**
	 * URL of the product page

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem[]
}
export interface CartViewed {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * Cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem[]
	/**
	 * Total value of the product after quantity

	 */
	value: string
}
export interface SampleDetailsItem1 {
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem1 {
	/**
	 * The color of the product

	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	paper_color?: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductsItem1 {
	/**
	 * Product Category displayed in the list

	 */
	category: string
	/**
	 * Coupon code associated with the product

	 */
	coupon: string
	/**
	 * Image url of the product in the list

	 */
	image_url: string
	/**
	 * Product Name displayed in the list

	 */
	name: string
	notes: string
	/**
	 * Position of product in the list

	 */
	position: number
	/**
	 * Product Price displayed in the list

	 */
	price: number
	/**
	 * Product ID displayed in the list

	 */
	product_id: string
	/**
	 * Product quantity displayed in the list

	 */
	quantity: number
	sample_details?: SampleDetailsItem1[]
	/**
	 * Product SKU displayed in the list

	 */
	sku: string
	/**
	 * URL of the product page for product in the list

	 */
	url: string
	/**
	 * Product Variant displayed in the list

	 */
	variant: VariantItem1[]
}
export interface CheckoutStarted {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * Cart ID associated with the product displayed

	 */
	cart_id: string
	/**
	 * Coupon code associated with the product

	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction

	 */
	currency: string
	/**
	 * Total discount associated with the transaction

	 */
	discount?: number
	/**
	 * Order/transaction ID

	 */
	order_id: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Products displayed in the product list

	 */
	products: ProductsItem1[]
	/**
	 * Revenue ($) associated with the transaction

	 */
	revenue: number
	/**
	 * Total value of the product after quantity

	 */
	value: string
}
export interface CouponApplied {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * Cart ID associated with the product displayed

	 */
	cart_id: string
	/**
	 * Coupon code associated with the product

	 */
	coupon: string
	/**
	 * Name of the coupon

	 */
	coupon_name: string
	/**
	 * Total discount associated with the transaction

	 */
	discount: number
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
}
export interface CouponDenied {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * Cart ID associated with the product displayed

	 */
	cart_id: string
	/**
	 * Coupon code associated with the product

	 */
	coupon: string
	/**
	 * Name of the coupon

	 */
	coupon_name?: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Reason the coupon was denied

	 */
	reason: string
}
export interface CouponEntered {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * Cart ID associated with the product displayed

	 */
	cart_id: string
	/**
	 * Coupon code associated with the product

	 */
	coupon: string
	/**
	 * Name of the coupon

	 */
	coupon_name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
}
export interface CouponRemoved {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * Cart ID associated with the product displayed

	 */
	cart_id: string
	/**
	 * Coupon code associated with the product

	 */
	coupon: string
	/**
	 * Name of the coupon

	 */
	coupon_name: string
	/**
	 * Total discount associated with the transaction

	 */
	discount: number
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
}
export interface SampleDetailsItem2 {
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem2 {
	/**
	 * The color of the product

	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface CustomizationInitialized {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * Product category being viewed

	 */
	category: string
	/**
	 * Notes the Design Editor Version we are on for Customer Canvas

	 */
	editor_version?: string
	/**
	 * Format of the customizer file being initialized
	 */
	file_type?: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't

	 */
	has_design_notes?: boolean
	/**
	 * Specifies the relevant section where the CTA interaction occurred, excluding page type

	 */
	location: string
	/**
	 * Name of the product

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product

	 */
	portfolio_item_id: string
	/**
	 * Price of the product

	 */
	price?: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity?: number
	/**
	 * Indicates whether the product selected is a sample (true/false)

	 */
	sample_details?: SampleDetailsItem2[]
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * Name of the paper surface that it's being customized

	 */
	surface?: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem2[]
}
export interface SampleDetailsItem3 {
	/**
	 * Indicates whether the product selected is a sample (true/false)

	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem3 {
	/**
	 * The color of the product

	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface CustomizationItemAdded {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.

	 */
	category: string
	/**
	 * Image url of the product in the list

	 */
	image_url: string
	/**
	 * This property reflects the type of item added in the design editor. There are 4 types of items that can be added: Text, Images, Shapes and QRCodes. To populate the property, you can be extract this straight from the CustomerCanvas event itself. Potential Values are "PlainTextItem", "BoundedTextItem", "ImageItem", "LineItem","RectangleItem", "EllipseItem", "BarcodeItem"
	 */
	item_type: string
	/**
	 * Product list being viewed
	 */
	list_id?: string
	/**
	 * Specifies the relevant section where the CTA interaction occurred, excluding page type

	 */
	location: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product

	 */
	portfolio_item_id: string
	/**
	 * Position of product in the list

	 */
	position: number
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity: number
	sample_details?: SampleDetailsItem3[]
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem3[]
}
export interface SampleDetailsItem4 {
	/**
	 * Indicates whether the product selected is a sample (true/false)

	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem4 {
	/**
	 * The color of the product

	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface CustomizationItemChanged {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.

	 */
	category: string
	/**
	 * This property is an array of strings and reflects the customization that a user applies to an existing element within the design.
	 */
	change_type: string
	count_of_changes: number
	/**
	 * This property reflects the type of item added in the design editor. There are 4 types of items that can be added: Text, Images, Shapes and QRCodes. To populate the property, you can be extract this straight from the CustomerCanvas event itself. Potential Values are "PlainTextItem", "BoundedTextItem", "ImageItem", "LineItem","RectangleItem", "EllipseItem", "BarcodeItem"
	 */
	item_type: string
	/**
	 * Specifies the relevant section where the CTA interaction occurred, excluding page type

	 */
	location: string
	/**
	 * Product Name displayed in the list
	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product

	 */
	portfolio_item_id: string
	/**
	 * Position of product in the list

	 */
	position: number
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity?: number
	sample_details?: SampleDetailsItem4[]
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem4[]
}
export interface SampleDetailsItem5 {
	/**
	 * Indicates whether the product selected is a sample (true/false)

	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem5 {
	/**
	 * The color of the product

	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface CustomizationItemRemoved {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.

	 */
	category: string
	/**
	 * Image url of the product in the list

	 */
	image_url: string
	/**
	 * This property reflects the type of item added in the design editor. There are 4 types of items that can be added: Text, Images, Shapes and QRCodes. To populate the property, you can be extract this straight from the CustomerCanvas event itself. Potential Values are "PlainTextItem", "BoundedTextItem", "ImageItem", "LineItem","RectangleItem", "EllipseItem", "BarcodeItem"
	 */
	item_type: string
	/**
	 * Specifies the relevant section where the CTA interaction occurred, excluding page type

	 */
	location: string
	/**
	 * Product Name displayed in the list

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product

	 */
	portfolio_item_id: string
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity?: number
	sample_details?: SampleDetailsItem5[]
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem5[]
}
export interface SampleDetailsItem6 {
	/**
	 * Indicates whether the product selected is a sample (true/false)

	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem6 {
	/**
	 * The color of the product

	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface CustomizationReviewInitialized {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.

	 */
	category: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't

	 */
	has_design_notes?: boolean
	/**
	 * Specifies the relevant section where the CTA interaction occurred, excluding page type

	 */
	location: string
	/**
	 * Product Name displayed in the list

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product

	 */
	portfolio_item_id: string
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity?: number
	sample_details?: SampleDetailsItem6[]
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * Name of the paper surface that it's being customized

	 */
	surface?: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem6[]
}
export interface SampleDetailsItem7 {
	/**
	 * Indicates whether the product selected is a sample (true/false)

	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem7 {
	/**
	 * The color of the product

	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface DraftCreated {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.

	 */
	category: string
	/**
	 * The identifier for a customer's saved draft
	 */
	draft_id: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't

	 */
	has_design_notes?: boolean
	/**
	 * Specifies the relevant section where the CTA interaction occurred, excluding page type

	 */
	location: string
	/**
	 * Product Name displayed in the list

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product

	 */
	portfolio_item_id: string
	/**
	 * Price of the product

	 */
	price: number
	product?: string
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity?: number
	sample_details?: SampleDetailsItem7[]
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * Name of the paper surface that it's being customized

	 */
	surface?: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem7[]
}
export interface SampleDetailsItem8 {
	/**
	 * Indicates whether the product selected is a sample (true/false)

	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem8 {
	/**
	 * The color of the product

	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface DraftDeleted {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.

	 */
	category: string
	/**
	 * The identifier for a customer's saved draft
	 */
	draft_id: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't

	 */
	has_design_notes?: boolean
	/**
	 * Specifies the relevant section where the CTA interaction occurred, excluding page type

	 */
	location: string
	/**
	 * Product Name displayed in the list

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product

	 */
	portfolio_item_id: string
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity?: number
	sample_details?: SampleDetailsItem8[]
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * Name of the paper surface that it's being customized

	 */
	surface?: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem8[]
}
export interface SampleDetailsItem9 {
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem9 {
	/**
	 * The color of the product
	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format: string
	/**
	 * Materials of the product
	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options?: string
}
export interface DraftsItem {
	/**
	 * Image url of the product in the list
	 */
	image_url: string
	/**
	 * Product Name displayed in the list
	 */
	name: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Database ID of the product/Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	sample_details?: SampleDetailsItem9[]
	/**
	 * Product SKU displayed in the list
	 */
	sku: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
	/**
	 * URL of the product page for product in the list
	 */
	url: string
	/**
	 * Product Variant displayed in the list
	 */
	variant: VariantItem9[]
}
export interface DraftListViewed {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * Category of the page
	 */
	category?: string
	drafts: DraftsItem[]
	/**
	 * Product list being viewed
	 */
	list_id: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:
	 */
	platform: string
}
export interface SampleDetailsItem10 {
	/**
	 * Indicates whether the product selected is a sample (true/false)

	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem10 {
	/**
	 * The color of the product

	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface DraftUpdated {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.

	 */
	category: string
	/**
	 * The identifier for a customer's saved draft
	 */
	draft_id: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't

	 */
	has_design_notes?: boolean
	/**
	 * Specifies the relevant section where the CTA interaction occurred, excluding page type

	 */
	location: string
	/**
	 * Product Name displayed in the list

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product

	 */
	portfolio_item_id: string
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity?: number
	sample_details?: SampleDetailsItem10[]
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * Name of the paper surface that it's being customized

	 */
	surface?: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem10[]
}
export interface FooterInteraction {
	/**
	 * "Reflects platform. Will leverage values identified by Data team:

	 */
	platform: string
	/**
	 * TK product: paper
	 */
	product?: string
	/**
	 * Footer Element selection
	 */
	selection?: string
}
export interface InformationEntered {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	information_type: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:

	 */
	platform: string
	/**
	 * String representing the shipping method chosen
	 */
	shipping_method: string
}
export interface LogIn {
	/**
	 * "Reflects platform. Will leverage values identified by Data team:

	 */
	platform: string
	product?: string
	reason?: string
	selection?: string
}
export interface LogInFormDisplayed {
	/**
	 * Type of flow: proactive
	 */
	flowType?: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:

	 */
	platform: string
	/**
	 * TK product: paper
	 */
	product?: string
	/**
	 * Reason and location
	 */
	reason?: string
	selection?: string
}
export interface MenuInteraction {
	/**
	 * Menu Element selection
	 */
	categoryName?: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:

	 */
	platform: string
	/**
	 * TK product: paper
	 */
	product?: string
	selection?: string
	source?: string
}
export interface NewRegistration {
	/**
	 * "Reflects platform. Will leverage values identified by Data team:

	 */
	platform: string
	product?: string
	reason?: string
	selection?: string
}
export interface SampleDetailsItem11 {
	/**
	 * Indicates whether the product selected is a sample (true/false)

	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem11 {
	/**
	 * The color of the product

	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	paper_color?: string
	/**
	 * Which printing option did the customer specify?

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductsItem2 {
	/**
	 * Product Category displayed in the list

	 */
	category: string
	/**
	 * Coupon code associated with the product

	 */
	coupon: string
	/**
	 * Image url of the product in the list

	 */
	image_url: string
	/**
	 * Product Name displayed in the list

	 */
	name: string
	/**
	 * Position of product in the list

	 */
	position: number
	/**
	 * Product Price displayed in the list

	 */
	price: number
	/**
	 * Product ID displayed in the list

	 */
	product_id: string
	/**
	 * Product quantity displayed in the list

	 */
	quantity: number
	sample_details?: SampleDetailsItem11[]
	/**
	 * Sku of the product being viewed

	 */
	sku: string
	/**
	 * URL of the product page

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem11[]
}
export interface OrderCompleted {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Total discount associated with the transaction
	 */
	discount?: number
	/**
	 * Order/transaction ID
	 */
	order_id: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:

	 */
	platform: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem2[]
	/**
	 * Revenue ($) associated with the transaction
	 */
	revenue: number
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping: number
	/**
	 * Order total after discounts but before taxes and shipping
	 */
	subtotal: number
	/**
	 * Total tax associated with the transaction
	 */
	tax: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total: number
}
export interface PageViewed {
	/**
	 * The category of the page, added to the properties object.
	 */
	category: string
	path?: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	referrer?: string
	resolution?: string
	search?: string
	/**
	 * Page title
	 */
	title: string
	/**
	 * URL of the product page for product in the list
	 */
	url: string
}
export interface SampleDetailsItem12 {
	/**
	 * Indicates whether the product selected is a sample (true/false)

	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem12 {
	/**
	 * The color of the product

	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	paper_color?: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductAdded {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * Product category being viewed

	 */
	category: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't

	 */
	has_design_notes?: boolean
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Name of the product

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity: number
	sample_details?: SampleDetailsItem12[]
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem12[]
}
export interface VariantItem13 {
	/**
	 * The color of the product

	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	paper_color?: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductClicked {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * Product category being viewed

	 */
	category: string
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Product list being viewed
	 */
	list_id: string
	/**
	 * Name of the product

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity: number
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem13[]
}
export interface SampleDetailsItem13 {
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem14 {
	/**
	 * The color of the product
	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format?: string
	/**
	 * Materials of the product
	 */
	material?: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductConfigured {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation?: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id?: string
	/**
	 * Product category being viewed

	 */
	category?: string
	/**
	 * The identifier for a customer's saved draft
	 */
	draft_id?: string
	/**
	 * Notes the Design Editor Version we are on for Customer Canvas
	 */
	editor_version?: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't
	 */
	has_design_notes?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Name of the product

	 */
	name?: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product
	 */
	portfolio_item_id?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product

	 */
	price?: number
	/**
	 * Database ID of the product

	 */
	product_id?: string
	/**
	 * Quantity of the product

	 */
	quantity?: number
	sample_details?: SampleDetailsItem13[]
	/**
	 * Sku of the product

	 */
	sku?: string
	/**
	 * Name of the paper surface that it's being customized
	 */
	surface?: string
	/**
	 * URL of the product page for product

	 */
	url?: string
	/**
	 * Variant of the product

	 */
	variant?: VariantItem14[]
}
export interface FiltersItem {
	/**
	 * Specifies the type of clicked element within the filtering system, distinguishing between badge and facet.
	 */
	click: string
	/**
	 * Id of the filter type that the customer is using
	 */
	type?: string
	/**
	 * Id of the selection that the customer chose
	 */
	value?: string
}
export interface VariantItem15 {
	/**
	 * The color of the product

	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	paper_color?: string
	/**
	 * Which printing option did the customer specify?

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductsItem3 {
	/**
	 * Product Category displayed in the list

	 */
	category: string
	/**
	 * Coupon code associated with the product

	 */
	coupon: string
	/**
	 * Image url of the product in the list

	 */
	image_url: string
	/**
	 * Product Name displayed in the list

	 */
	name: string
	/**
	 * Position of product in the list

	 */
	position: number
	/**
	 * Product Price displayed in the list

	 */
	price: number
	/**
	 * Product ID displayed in the list

	 */
	product_id: string
	/**
	 * Product quantity displayed in the list

	 */
	quantity: number
	/**
	 * Sku of the product being viewed

	 */
	sku: string
	/**
	 * URL of the product page

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem15[]
}
export interface SelectedFilter {
	/**
	 * Specifies the type of clicked element within the filtering system, distinguishing between badge and facet.
	 */
	click: string
	/**
	 * ID of the filter type that the customer is using
	 */
	flag?: string
	/**
	 * ID of the filter type that the customer is using
	 */
	type?: string
	/**
	 * ID of the selection that the customer chose
	 */
	value?: string
}
export interface SortsItem {
	/**
	 * ID of the sort type being used
	 */
	type: string
	/**
	 * ID of the selection type being used
	 */
	value?: string
}
export interface ProductListFiltered {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.
	 */
	category?: string
	/**
	 * Product filters that the customer is using
	 */
	filters?: FiltersItem[]
	/**
	 * Product list being viewed
	 */
	list_id: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
	 */
	platform: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem3[]
	/**
	 * The latest filter a customer has applied
	 */
	selected_filter?: SelectedFilter
	/**
	 * Product sorting the customer is using
	 */
	sorts?: SortsItem[]
}
export interface SampleDetailsItem14 {
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem16 {
	/**
	 * The color of the product

	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	paper_color?: string
	/**
	 * Which printing option did the customer specify?

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductsItem4 {
	/**
	 * Product Category displayed in the list

	 */
	category: string
	/**
	 * Coupon code associated with the product

	 */
	coupon: string
	/**
	 * Image url of the product in the list

	 */
	image_url: string
	/**
	 * Product Name displayed in the list

	 */
	name: string
	/**
	 * Position of product in the list

	 */
	position: number
	/**
	 * Product Price displayed in the list

	 */
	price: number
	/**
	 * Product ID displayed in the list

	 */
	product_id: string
	/**
	 * Product quantity displayed in the list

	 */
	quantity: number
	sample_details?: SampleDetailsItem14[]
	/**
	 * Sku of the product being viewed

	 */
	sku: string
	/**
	 * URL of the product page

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem16[]
}
export interface ProductListViewed {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.
	 */
	category?: string
	/**
	 * Product list being viewed
	 */
	list_id: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
	 */
	platform: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem4[]
}
export interface SampleDetailsItem15 {
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem17 {
	/**
	 * The color of the product

	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductMoved {
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * Product category being viewed

	 */
	category: string
	/**
	 * Shopping cart ID - this is a replicate of the cart_id field
	 */
	checkout_id: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't

	 */
	has_design_notes: boolean
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Name of the product

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product
	 */
	portfolio_item_id: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity: number
	sample_details?: SampleDetailsItem15[]
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * Name of the paper surface that it's being customized
	 */
	surface?: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem17[]
}
export interface VariantItem18 {
	/**
	 * The color of the product

	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	paper_color?: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductQuicklooked {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * Product category being viewed

	 */
	category: string
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Product list being viewed
	 */
	list_id: string
	/**
	 * Name of the product

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity: number
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem18[]
}
export interface SampleDetailsItem16 {
	/**
	 * Indicates whether the product selected is a sample (true/false)

	 */
	is_sample: boolean
	personalized?: boolean
}
export interface VariantItem19 {
	/**
	 * The color of the product

	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	paper_color?: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductRemoved {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * Product category being viewed

	 */
	category: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't

	 */
	has_design_notes: boolean
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Name of the product

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity: number
	sample_details?: SampleDetailsItem16[]
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem19[]
}
export interface VariantItem20 {
	/**
	 * The color of the product

	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.

	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

	 */
	format: string
	/**
	 * Materials of the product

	 */
	material: string
	paper_color?: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

	 */
	printing_options?: string
	/**
	 * Trim shape of the product
	 */
	trim_shape?: string
}
export interface ProductViewed {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * Product category being viewed

	 */
	category: string
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Name of the product

	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Price of the product

	 */
	price: number
	/**
	 * Database ID of the product

	 */
	product_id: string
	/**
	 * Quantity of the product

	 */
	quantity: number
	/**
	 * Sku of the product

	 */
	sku: string
	/**
	 * URL of the product page for product

	 */
	url: string
	/**
	 * Variant of the product

	 */
	variant: VariantItem20[]
}
export interface ProductsSearched {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Query the user searched with
	 */
	query: string
}
export interface PromotionClicked {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * User clicked on promotion
	 */
	creative: string
	/**
	 * Product Name displayed in the list
	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Position of the promotion
	 */
	position: string
	/**
	 * ID of the promotion
	 */
	promotion_id: string
}
export interface PromotionViewed {
	/**
	 * Store or affiliation from which this transaction occurred

	 */
	affiliation: string
	/**
	 * User clicked on promotion
	 */
	creative: string
	/**
	 * Product Name displayed in the list
	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * Position of the promotion
	 */
	position: string
	/**
	 * ID of the promotion
	 */
	promotion_id: string
}
export interface RegistrationFormDisplayed {
	/**
	 * Type of flow: proactive
	 */
	flowType?: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

	 */
	platform: string
	/**
	 * TK product: paper
	 */
	product?: string
	/**
	 * Reason and location
	 */
	reason?: string
	selection?: string
	/**
	 * Step
	 */
	stepCount?: number
	/**
	 * Step
	 */
	stepNumber?: number
}
export interface SearchInitialized {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.
	 */
	category: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"
	 */
	platform: string
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef ExperimentStarted
 * @property {string} Experiment name - the name of the variant into which the user was bucketed, for that experiment

 * @property {string} Trigger path - 
 * @property {string} Variant name - the name of the experiment to which the user has been exposed

 */
/**
 * @typedef CtaClicked
 * @property {string} [category] - 
 * @property {string} [element_clicked] - 
 * @property {string} [location] - 
 * @property {string} [platform] - 
 * @property {string} [product] - 
 * @property {string} [product_id] - Database ID of the product

 * @property {string} [reason] - 
 */
/**
 * @typedef SampleDetailsItem
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)
 * @property {boolean} [personalized] -
 */
/**
 * @typedef VariantItem
 * @property {string} color - The color of the product

 * @property {string} [flap_type] - 
 * @property {string} foil_color - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [paper_color] - 
 * @property {string} [printing_options] - Which printing option did the customer specify?

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductsItem
 * @property {string} category - Product Category displayed in the list

 * @property {string} [coupon] - Coupon code associated with the product

 * @property {string} image_url - Image url of the product in the list

 * @property {string} name - Product Name displayed in the list

 * @property {number} position - Position of product in the list

 * @property {number} price - Product Price displayed in the list

 * @property {string} product_id - Product ID displayed in the list

 * @property {number} quantity - Product quantity displayed in the list

 * @property {SampleDetailsItem[]} [sample_details] - 
 * @property {string} sku - Sku of the product being viewed

 * @property {string} url - URL of the product page

 * @property {VariantItem[]} variant - Variant of the product

 */
/**
 * @typedef CartViewed
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} cart_id - Cart ID associated with the product displayed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {ProductsItem[]} products - Products displayed in the product list
 * @property {string} value - Total value of the product after quantity

 */
/**
 * @typedef SampleDetailsItem1
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)
 * @property {boolean} [personalized] -
 */
/**
 * @typedef VariantItem1
 * @property {string} color - The color of the product

 * @property {string} [flap_type] - 
 * @property {string} foil_color - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [paper_color] - 
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductsItem1
 * @property {string} category - Product Category displayed in the list

 * @property {string} coupon - Coupon code associated with the product

 * @property {string} image_url - Image url of the product in the list

 * @property {string} name - Product Name displayed in the list

 * @property {string} notes - 
 * @property {number} position - Position of product in the list

 * @property {number} price - Product Price displayed in the list

 * @property {string} product_id - Product ID displayed in the list

 * @property {number} quantity - Product quantity displayed in the list

 * @property {SampleDetailsItem1[]} [sample_details] - 
 * @property {string} sku - Product SKU displayed in the list

 * @property {string} url - URL of the product page for product in the list

 * @property {VariantItem1[]} variant - Product Variant displayed in the list

 */
/**
 * @typedef CheckoutStarted
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} cart_id - Cart ID associated with the product displayed

 * @property {string} [coupon] - Coupon code associated with the product

 * @property {string} currency - Currency code associated with the transaction

 * @property {number} [discount] - Total discount associated with the transaction

 * @property {string} order_id - Order/transaction ID

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {ProductsItem1[]} products - Products displayed in the product list

 * @property {number} revenue - Revenue ($) associated with the transaction

 * @property {string} value - Total value of the product after quantity

 */
/**
 * @typedef CouponApplied
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} cart_id - Cart ID associated with the product displayed

 * @property {string} coupon - Coupon code associated with the product

 * @property {string} coupon_name - Name of the coupon

 * @property {number} discount - Total discount associated with the transaction

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 */
/**
 * @typedef CouponDenied
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} cart_id - Cart ID associated with the product displayed

 * @property {string} coupon - Coupon code associated with the product

 * @property {string} [coupon_name] - Name of the coupon

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} reason - Reason the coupon was denied

 */
/**
 * @typedef CouponEntered
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} cart_id - Cart ID associated with the product displayed

 * @property {string} coupon - Coupon code associated with the product

 * @property {string} coupon_name - Name of the coupon

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 */
/**
 * @typedef CouponRemoved
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} cart_id - Cart ID associated with the product displayed

 * @property {string} coupon - Coupon code associated with the product

 * @property {string} coupon_name - Name of the coupon

 * @property {number} discount - Total discount associated with the transaction

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 */
/**
 * @typedef SampleDetailsItem2
 * @property {boolean} is_sample -
 * @property {boolean} [personalized] -
 */
/**
 * @typedef VariantItem2
 * @property {string} color - The color of the product

 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef CustomizationInitialized
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} category - Product category being viewed

 * @property {string} [editor_version] - Notes the Design Editor Version we are on for Customer Canvas

 * @property {string} [file_type] - Format of the customizer file being initialized
 * @property {boolean} [has_design_notes] - Whether a product customization has a design note. If yes, it does. if no, it doesn't

 * @property {string} location - Specifies the relevant section where the CTA interaction occurred, excluding page type

 * @property {string} name - Name of the product

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} portfolio_item_id - Identifier of a customer's created customized product

 * @property {number} [price] - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} [quantity] - Quantity of the product

 * @property {SampleDetailsItem2[]} [sample_details] - Indicates whether the product selected is a sample (true/false)

 * @property {string} sku - Sku of the product

 * @property {string} [surface] - Name of the paper surface that it's being customized

 * @property {string} url - URL of the product page for product

 * @property {VariantItem2[]} variant - Variant of the product

 */
/**
 * @typedef SampleDetailsItem3
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)

 * @property {boolean} [personalized] - 
 */
/**
 * @typedef VariantItem3
 * @property {string} color - The color of the product

 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef CustomizationItemAdded
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} category - The category of the page, added to the properties object.

 * @property {string} image_url - Image url of the product in the list

 * @property {string} item_type - This property reflects the type of item added in the design editor. There are 4 types of items that can be added: Text, Images, Shapes and QRCodes. To populate the property, you can be extract this straight from the CustomerCanvas event itself. Potential Values are "PlainTextItem", "BoundedTextItem", "ImageItem", "LineItem","RectangleItem", "EllipseItem", "BarcodeItem"
 * @property {string} [list_id] - Product list being viewed
 * @property {string} location - Specifies the relevant section where the CTA interaction occurred, excluding page type

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} portfolio_item_id - Identifier of a customer's created customized product

 * @property {number} position - Position of product in the list

 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} quantity - Quantity of the product

 * @property {SampleDetailsItem3[]} [sample_details] - 
 * @property {string} sku - Sku of the product

 * @property {string} url - URL of the product page for product

 * @property {VariantItem3[]} variant - Variant of the product

 */
/**
 * @typedef SampleDetailsItem4
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)

 * @property {boolean} [personalized] - 
 */
/**
 * @typedef VariantItem4
 * @property {string} color - The color of the product

 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef CustomizationItemChanged
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} category - The category of the page, added to the properties object.

 * @property {string} change_type - This property is an array of strings and reflects the customization that a user applies to an existing element within the design.
 * @property {number} count_of_changes - 
 * @property {string} item_type - This property reflects the type of item added in the design editor. There are 4 types of items that can be added: Text, Images, Shapes and QRCodes. To populate the property, you can be extract this straight from the CustomerCanvas event itself. Potential Values are "PlainTextItem", "BoundedTextItem", "ImageItem", "LineItem","RectangleItem", "EllipseItem", "BarcodeItem"
 * @property {string} location - Specifies the relevant section where the CTA interaction occurred, excluding page type

 * @property {string} name - Product Name displayed in the list
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} portfolio_item_id - Identifier of a customer's created customized product

 * @property {number} position - Position of product in the list

 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} [quantity] - Quantity of the product

 * @property {SampleDetailsItem4[]} [sample_details] - 
 * @property {string} sku - Sku of the product

 * @property {string} url - URL of the product page for product

 * @property {VariantItem4[]} variant - Variant of the product

 */
/**
 * @typedef SampleDetailsItem5
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)

 * @property {boolean} [personalized] - 
 */
/**
 * @typedef VariantItem5
 * @property {string} color - The color of the product

 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef CustomizationItemRemoved
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} category - The category of the page, added to the properties object.

 * @property {string} image_url - Image url of the product in the list

 * @property {string} item_type - This property reflects the type of item added in the design editor. There are 4 types of items that can be added: Text, Images, Shapes and QRCodes. To populate the property, you can be extract this straight from the CustomerCanvas event itself. Potential Values are "PlainTextItem", "BoundedTextItem", "ImageItem", "LineItem","RectangleItem", "EllipseItem", "BarcodeItem"
 * @property {string} location - Specifies the relevant section where the CTA interaction occurred, excluding page type

 * @property {string} name - Product Name displayed in the list

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} portfolio_item_id - Identifier of a customer's created customized product

 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} [quantity] - Quantity of the product

 * @property {SampleDetailsItem5[]} [sample_details] - 
 * @property {string} sku - Sku of the product

 * @property {string} url - URL of the product page for product

 * @property {VariantItem5[]} variant - Variant of the product

 */
/**
 * @typedef SampleDetailsItem6
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)

 * @property {boolean} [personalized] - 
 */
/**
 * @typedef VariantItem6
 * @property {string} color - The color of the product

 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef CustomizationReviewInitialized
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} category - The category of the page, added to the properties object.

 * @property {boolean} [has_design_notes] - Whether a product customization has a design note. If yes, it does. if no, it doesn't

 * @property {string} location - Specifies the relevant section where the CTA interaction occurred, excluding page type

 * @property {string} name - Product Name displayed in the list

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} portfolio_item_id - Identifier of a customer's created customized product

 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} [quantity] - Quantity of the product

 * @property {SampleDetailsItem6[]} [sample_details] - 
 * @property {string} sku - Sku of the product

 * @property {string} [surface] - Name of the paper surface that it's being customized

 * @property {string} url - URL of the product page for product

 * @property {VariantItem6[]} variant - Variant of the product

 */
/**
 * @typedef SampleDetailsItem7
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)

 * @property {boolean} [personalized] - 
 */
/**
 * @typedef VariantItem7
 * @property {string} color - The color of the product

 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef DraftCreated
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} category - The category of the page, added to the properties object.

 * @property {string} draft_id - The identifier for a customer's saved draft
 * @property {boolean} [has_design_notes] - Whether a product customization has a design note. If yes, it does. if no, it doesn't

 * @property {string} location - Specifies the relevant section where the CTA interaction occurred, excluding page type

 * @property {string} name - Product Name displayed in the list

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} portfolio_item_id - Identifier of a customer's created customized product

 * @property {number} price - Price of the product

 * @property {string} [product] - 
 * @property {string} product_id - Database ID of the product

 * @property {number} [quantity] - Quantity of the product

 * @property {SampleDetailsItem7[]} [sample_details] - 
 * @property {string} sku - Sku of the product

 * @property {string} [surface] - Name of the paper surface that it's being customized

 * @property {string} url - URL of the product page for product

 * @property {VariantItem7[]} variant - Variant of the product

 */
/**
 * @typedef SampleDetailsItem8
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)

 * @property {boolean} [personalized] - 
 */
/**
 * @typedef VariantItem8
 * @property {string} color - The color of the product

 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef DraftDeleted
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} category - The category of the page, added to the properties object.

 * @property {string} draft_id - The identifier for a customer's saved draft
 * @property {boolean} [has_design_notes] - Whether a product customization has a design note. If yes, it does. if no, it doesn't

 * @property {string} location - Specifies the relevant section where the CTA interaction occurred, excluding page type

 * @property {string} name - Product Name displayed in the list

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} portfolio_item_id - Identifier of a customer's created customized product

 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} [quantity] - Quantity of the product

 * @property {SampleDetailsItem8[]} [sample_details] - 
 * @property {string} sku - Sku of the product

 * @property {string} [surface] - Name of the paper surface that it's being customized

 * @property {string} url - URL of the product page for product

 * @property {VariantItem8[]} variant - Variant of the product

 */
/**
 * @typedef SampleDetailsItem9
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)
 * @property {boolean} [personalized] -
 */
/**
 * @typedef VariantItem9
 * @property {string} color - The color of the product
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} material - Materials of the product
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 */
/**
 * @typedef DraftsItem
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {number} position - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Database ID of the product/Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {SampleDetailsItem9[]} [sample_details] -
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} [trim_shape] - Trim shape of the product
 * @property {string} url - URL of the product page for product in the list
 * @property {VariantItem9[]} variant - Product Variant displayed in the list
 */
/**
 * @typedef DraftListViewed
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} [category] - Category of the page
 * @property {DraftsItem[]} drafts -
 * @property {string} list_id - Product list being viewed
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:
 */
/**
 * @typedef SampleDetailsItem10
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)

 * @property {boolean} [personalized] - 
 */
/**
 * @typedef VariantItem10
 * @property {string} color - The color of the product

 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef DraftUpdated
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} category - The category of the page, added to the properties object.

 * @property {string} draft_id - The identifier for a customer's saved draft
 * @property {boolean} [has_design_notes] - Whether a product customization has a design note. If yes, it does. if no, it doesn't

 * @property {string} location - Specifies the relevant section where the CTA interaction occurred, excluding page type

 * @property {string} name - Product Name displayed in the list

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} portfolio_item_id - Identifier of a customer's created customized product

 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} [quantity] - Quantity of the product

 * @property {SampleDetailsItem10[]} [sample_details] - 
 * @property {string} sku - Sku of the product

 * @property {string} [surface] - Name of the paper surface that it's being customized

 * @property {string} url - URL of the product page for product

 * @property {VariantItem10[]} variant - Variant of the product

 */
/**
 * @typedef FooterInteraction
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:

 * @property {string} [product] - TK product: paper
 * @property {string} [selection] - Footer Element selection
 */
/**
 * @typedef InformationEntered
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} information_type - 
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:

 * @property {string} shipping_method - String representing the shipping method chosen
 */
/**
 * @typedef LogIn
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:

 * @property {string} [product] - 
 * @property {string} [reason] - 
 * @property {string} [selection] - 
 */
/**
 * @typedef LogInFormDisplayed
 * @property {string} [flowType] - Type of flow: proactive
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:

 * @property {string} [product] - TK product: paper
 * @property {string} [reason] - Reason and location
 * @property {string} [selection] - 
 */
/**
 * @typedef MenuInteraction
 * @property {string} [categoryName] - Menu Element selection
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:

 * @property {string} [product] - TK product: paper
 * @property {string} [selection] - 
 * @property {string} [source] - 
 */
/**
 * @typedef NewRegistration
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:

 * @property {string} [product] - 
 * @property {string} [reason] - 
 * @property {string} [selection] - 
 */
/**
 * @typedef SampleDetailsItem11
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)

 * @property {boolean} [personalized] - 
 */
/**
 * @typedef VariantItem11
 * @property {string} color - The color of the product

 * @property {string} [flap_type] - 
 * @property {string} foil_color - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [paper_color] - 
 * @property {string} [printing_options] - Which printing option did the customer specify?

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductsItem2
 * @property {string} category - Product Category displayed in the list

 * @property {string} coupon - Coupon code associated with the product

 * @property {string} image_url - Image url of the product in the list

 * @property {string} name - Product Name displayed in the list

 * @property {number} position - Position of product in the list

 * @property {number} price - Product Price displayed in the list

 * @property {string} product_id - Product ID displayed in the list

 * @property {number} quantity - Product quantity displayed in the list

 * @property {SampleDetailsItem11[]} [sample_details] - 
 * @property {string} sku - Sku of the product being viewed

 * @property {string} url - URL of the product page

 * @property {VariantItem11[]} variant - Variant of the product

 */
/**
 * @typedef OrderCompleted
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:

 * @property {ProductsItem2[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} shipping - Shipping cost associated with the transaction
 * @property {number} subtotal - Order total after discounts but before taxes and shipping
 * @property {number} tax - Total tax associated with the transaction
 * @property {number} total - Revenue with discounts and coupons added
 */
/**
 * @typedef PageViewed
 * @property {string} category - The category of the page, added to the properties object.
 * @property {string} [path] - 
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} [referrer] - 
 * @property {string} [resolution] - 
 * @property {string} [search] - 
 * @property {string} title - Page title
 * @property {string} url - URL of the product page for product in the list
 */
/**
 * @typedef SampleDetailsItem12
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)

 * @property {boolean} [personalized] - 
 */
/**
 * @typedef VariantItem12
 * @property {string} color - The color of the product

 * @property {string} [flap_type] - 
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [paper_color] - 
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductAdded
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} category - Product category being viewed

 * @property {boolean} [has_design_notes] - Whether a product customization has a design note. If yes, it does. if no, it doesn't

 * @property {string} image_url - Image url of the product
 * @property {string} name - Name of the product

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {number} position - Position of product in the list
 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} quantity - Quantity of the product

 * @property {SampleDetailsItem12[]} [sample_details] - 
 * @property {string} sku - Sku of the product

 * @property {string} url - URL of the product page for product

 * @property {VariantItem12[]} variant - Variant of the product

 */
/**
 * @typedef VariantItem13
 * @property {string} color - The color of the product

 * @property {string} [flap_type] - 
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [paper_color] - 
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductClicked
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} category - Product category being viewed

 * @property {string} image_url - Image url of the product
 * @property {string} list_id - Product list being viewed
 * @property {string} name - Name of the product

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {number} position - Position of product in the list
 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} quantity - Quantity of the product

 * @property {string} sku - Sku of the product

 * @property {string} url - URL of the product page for product

 * @property {VariantItem13[]} variant - Variant of the product

 */
/**
 * @typedef SampleDetailsItem13
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)
 * @property {boolean} [personalized] -
 */
/**
 * @typedef VariantItem14
 * @property {string} color - The color of the product
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} [format] - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} [material] - Materials of the product
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductConfigured
 * @property {string} [affiliation] - Store or affiliation from which this transaction occurred
 * @property {string} [cart_id] - cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed

 * @property {string} [draft_id] - The identifier for a customer's saved draft
 * @property {string} [editor_version] - Notes the Design Editor Version we are on for Customer Canvas
 * @property {string} [has_design_notes] - Whether a product customization has a design note. If yes, it does. if no, it doesn't 
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} [portfolio_item_id] - Identifier of a customer's created customized product
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Price of the product

 * @property {string} [product_id] - Database ID of the product

 * @property {number} [quantity] - Quantity of the product

 * @property {SampleDetailsItem13[]} [sample_details] - 
 * @property {string} [sku] - Sku of the product

 * @property {string} [surface] - Name of the paper surface that it's being customized 
 * @property {string} [url] - URL of the product page for product

 * @property {VariantItem14[]} [variant] - Variant of the product

 */
/**
 * @typedef FiltersItem
 * @property {string} click - Specifies the type of clicked element within the filtering system, distinguishing between badge and facet.
 * @property {string} [type] - Id of the filter type that the customer is using
 * @property {string} [value] - Id of the selection that the customer chose
 */
/**
 * @typedef VariantItem15
 * @property {string} color - The color of the product

 * @property {string} [flap_type] - 
 * @property {string} foil_color - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [paper_color] - 
 * @property {string} [printing_options] - Which printing option did the customer specify?

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductsItem3
 * @property {string} category - Product Category displayed in the list

 * @property {string} coupon - Coupon code associated with the product

 * @property {string} image_url - Image url of the product in the list

 * @property {string} name - Product Name displayed in the list

 * @property {number} position - Position of product in the list

 * @property {number} price - Product Price displayed in the list

 * @property {string} product_id - Product ID displayed in the list

 * @property {number} quantity - Product quantity displayed in the list

 * @property {string} sku - Sku of the product being viewed

 * @property {string} url - URL of the product page

 * @property {VariantItem15[]} variant - Variant of the product

 */
/**
 * @typedef SelectedFilter
 * @property {string} click - Specifies the type of clicked element within the filtering system, distinguishing between badge and facet.
 * @property {string} [flag] - ID of the filter type that the customer is using
 * @property {string} [type] - ID of the filter type that the customer is using
 * @property {string} [value] - ID of the selection that the customer chose
 */
/**
 * @typedef SortsItem
 * @property {string} type - ID of the sort type being used
 * @property {string} [value] - ID of the selection type being used
 */
/**
 * @typedef ProductListFiltered
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} [category] - The category of the page, added to the properties object.
 * @property {FiltersItem[]} [filters] - Product filters that the customer is using
 * @property {string} list_id - Product list being viewed
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
 * @property {ProductsItem3[]} products - Products displayed in the product list
 * @property {SelectedFilter} [selected_filter] - The latest filter a customer has applied
 * @property {SortsItem[]} [sorts] - Product sorting the customer is using
 */
/**
 * @typedef SampleDetailsItem14
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)
 * @property {boolean} [personalized] -
 */
/**
 * @typedef VariantItem16
 * @property {string} color - The color of the product

 * @property {string} [flap_type] - 
 * @property {string} foil_color - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [paper_color] - 
 * @property {string} [printing_options] - Which printing option did the customer specify?

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductsItem4
 * @property {string} category - Product Category displayed in the list

 * @property {string} coupon - Coupon code associated with the product

 * @property {string} image_url - Image url of the product in the list

 * @property {string} name - Product Name displayed in the list

 * @property {number} position - Position of product in the list

 * @property {number} price - Product Price displayed in the list

 * @property {string} product_id - Product ID displayed in the list

 * @property {number} quantity - Product quantity displayed in the list

 * @property {SampleDetailsItem14[]} [sample_details] - 
 * @property {string} sku - Sku of the product being viewed

 * @property {string} url - URL of the product page

 * @property {VariantItem16[]} variant - Variant of the product

 */
/**
 * @typedef ProductListViewed
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} [category] - The category of the page, added to the properties object.
 * @property {string} list_id - Product list being viewed
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
 * @property {ProductsItem4[]} products - Products displayed in the product list
 */
/**
 * @typedef SampleDetailsItem15
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)
 * @property {boolean} [personalized] -
 */
/**
 * @typedef VariantItem17
 * @property {string} color - The color of the product

 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductMoved
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} category - Product category being viewed

 * @property {string} checkout_id - Shopping cart ID - this is a replicate of the cart_id field
 * @property {boolean} has_design_notes - Whether a product customization has a design note. If yes, it does. if no, it doesn't

 * @property {string} image_url - Image url of the product
 * @property {string} name - Name of the product

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} portfolio_item_id - Identifier of a customer's created customized product
 * @property {number} position - Position of product in the list
 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} quantity - Quantity of the product

 * @property {SampleDetailsItem15[]} [sample_details] - 
 * @property {string} sku - Sku of the product

 * @property {string} [surface] - Name of the paper surface that it's being customized 
 * @property {string} url - URL of the product page for product

 * @property {VariantItem17[]} variant - Variant of the product

 */
/**
 * @typedef VariantItem18
 * @property {string} color - The color of the product

 * @property {string} [flap_type] - 
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [paper_color] - 
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductQuicklooked
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} category - Product category being viewed

 * @property {string} image_url - Image url of the product
 * @property {string} list_id - Product list being viewed
 * @property {string} name - Name of the product

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {number} position - Position of product in the list
 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} quantity - Quantity of the product

 * @property {string} sku - Sku of the product

 * @property {string} url - URL of the product page for product

 * @property {VariantItem18[]} variant - Variant of the product

 */
/**
 * @typedef SampleDetailsItem16
 * @property {boolean} is_sample - Indicates whether the product selected is a sample (true/false)

 * @property {boolean} [personalized] - 
 */
/**
 * @typedef VariantItem19
 * @property {string} color - The color of the product

 * @property {string} [flap_type] - 
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [paper_color] - 
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductRemoved
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} category - Product category being viewed

 * @property {string} [coupon] - Coupon code associated with the product
 * @property {boolean} has_design_notes - Whether a product customization has a design note. If yes, it does. if no, it doesn't

 * @property {string} image_url - Image url of the product
 * @property {string} name - Name of the product

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {number} position - Position of product in the list
 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} quantity - Quantity of the product

 * @property {SampleDetailsItem16[]} [sample_details] - 
 * @property {string} sku - Sku of the product

 * @property {string} url - URL of the product page for product

 * @property {VariantItem19[]} variant - Variant of the product

 */
/**
 * @typedef VariantItem20
 * @property {string} color - The color of the product

 * @property {string} [flap_type] - 
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.

 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc

 * @property {string} material - Materials of the product

 * @property {string} [paper_color] - 
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"

 * @property {string} [trim_shape] - Trim shape of the product
 */
/**
 * @typedef ProductViewed
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} category - Product category being viewed

 * @property {string} image_url - Image url of the product
 * @property {string} name - Name of the product

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {number} position - Position of product in the list
 * @property {number} price - Price of the product

 * @property {string} product_id - Database ID of the product

 * @property {number} quantity - Quantity of the product

 * @property {string} sku - Sku of the product

 * @property {string} url - URL of the product page for product

 * @property {VariantItem20[]} variant - Variant of the product

 */
/**
 * @typedef ProductsSearched
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} query - Query the user searched with
 */
/**
 * @typedef PromotionClicked
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} creative - User clicked on promotion
 * @property {string} name - Product Name displayed in the list
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} position - Position of the promotion
 * @property {string} promotion_id - ID of the promotion
 */
/**
 * @typedef PromotionViewed
 * @property {string} affiliation - Store or affiliation from which this transaction occurred

 * @property {string} creative - User clicked on promotion
 * @property {string} name - Product Name displayed in the list
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} position - Position of the promotion
 * @property {string} promotion_id - ID of the promotion
 */
/**
 * @typedef RegistrationFormDisplayed
 * @property {string} [flowType] - Type of flow: proactive
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"

 * @property {string} [product] - TK product: paper
 * @property {string} [reason] - Reason and location
 * @property {string} [selection] - 
 * @property {number} [stepCount] - Step
 * @property {number} [stepNumber] - Step
 */
/**
 * @typedef SearchInitialized
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} category - The category of the page, added to the properties object.
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team: mobile app ios mobile app android desktop web mobile web"
 */

/**
 * Mixpanel experiment
 *
 * @param {ExperimentStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function experimentStarted(
	props: ExperimentStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'$experiment_started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'CTA Clicked' track call.
 *
 * @param {CtaClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ctaClicked(
	props?: CtaClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'CTA Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Cart Viewed' track call.
 *
 * @param {CartViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartViewed(
	props: CartViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cart Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Checkout Started' track call.
 *
 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(
	props: CheckoutStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Checkout Step Completed' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepCompleted(
	props?: Record<string, any>,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Coupon was applied on a user’s shopping cart or order

 *
 * @param {CouponApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponApplied(
	props: CouponApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Coupon was denied from a user’s shopping cart or order

 *
 * @param {CouponDenied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponDenied(
	props: CouponDenied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Denied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User entered a coupon on a shopping cart or order

 *
 * @param {CouponEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponEntered(
	props: CouponEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a coupon from a cart or order

 *
 * @param {CouponRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponRemoved(
	props: CouponRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User started a customization

 *
 * @param {CustomizationInitialized} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationInitialized(
	props: CustomizationInitialized,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Initialized',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the action of items (image, text, shape or QR Code) is removed within the design editor. It maps to the event fired by Customer Canvas Platform
 *
 * @param {CustomizationItemAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationItemAdded(
	props: CustomizationItemAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Item Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the action of items (image, text, shape or QR Code) is removed within the design editor. It maps to the event fired by Customer Canvas Platform
 *
 * @param {CustomizationItemChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationItemChanged(
	props: CustomizationItemChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Item Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the action of items (image, text, shape or QR Code) is removed within the design editor. It maps to the event fired by Customer Canvas Platform
 *
 * @param {CustomizationItemRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationItemRemoved(
	props: CustomizationItemRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Item Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User saved and completed the current customization
 *
 * @param {CustomizationReviewInitialized} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationReviewInitialized(
	props: CustomizationReviewInitialized,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Review Initialized',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Draft Created' track call.
 *
 * @param {DraftCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function draftCreated(
	props?: DraftCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Draft Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Draft Deleted' track call.
 *
 * @param {DraftDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function draftDeleted(
	props: DraftDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Draft Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Draft List Viewed' track call.
 *
 * @param {DraftListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function draftListViewed(
	props: DraftListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Draft List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Draft Updated' track call.
 *
 * @param {DraftUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function draftUpdated(
	props: DraftUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Draft Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Footer Interaction' track call.
 *
 * @param {FooterInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function footerInteraction(
	props?: FooterInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Footer Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Information Entered' track call.
 *
 * @param {InformationEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function informationEntered(
	props: InformationEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Information Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Log In' track call.
 *
 * @param {LogIn} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function logIn(
	props?: LogIn,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Log In', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Fires a 'Log In Form Displayed' track call.
 *
 * @param {LogInFormDisplayed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function logInFormDisplayed(
	props?: LogInFormDisplayed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Log In Form Displayed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Menu Interaction' track call.
 *
 * @param {MenuInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteraction(
	props?: MenuInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'New Registration' track call.
 *
 * @param {NewRegistration} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newRegistration(
	props?: NewRegistration,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Registration',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Order Completed' track call.
 *
 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCompleted(
	props: OrderCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Page Viewed' track call.
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added a product to their cart
 *
 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAdded(
	props: ProductAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked through to a product page
 *
 * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productClicked(
	props: ProductClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updated configuration of product on product detail page
 *
 * @param {ProductConfigured} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productConfigured(
	props: ProductConfigured,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Configured',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User filtered a product list or category
 *
 * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListFiltered(
	props: ProductListFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product List Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User filtered a product list or category
 *
 * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListViewed(
	props: ProductListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Product is moved from Cart to Drafts
 *
 * @param {ProductMoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productMoved(
	props: ProductMoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Moved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked a product
 *
 * @param {ProductQuicklooked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productQuicklooked(
	props: ProductQuicklooked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Quicklooked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Product is removed from Cart by clicking "Remove" link located in the Cart
 *
 * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemoved(
	props: ProductRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User views a product
 *
 * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productViewed(
	props: ProductViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User searched for products
 *
 * @param {ProductsSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsSearched(
	props: ProductsSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Products Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked on promotion (banner) or a corresponding CTA
 *
 * @param {PromotionClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionClicked(
	props: PromotionClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Promotion Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed promotion
 *
 * @param {PromotionViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionViewed(
	props: PromotionViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Promotion Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Registration Form Displayed' track call.
 *
 * @param {RegistrationFormDisplayed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationFormDisplayed(
	props?: RegistrationFormDisplayed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Registration Form Displayed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Search Initialized' track call.
 *
 * @param {SearchInitialized} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchInitialized(
	props: SearchInitialized,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Initialized',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Mixpanel experiment
	 *
	 * @param {ExperimentStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	experimentStarted,
	/**
	 * Fires a 'CTA Clicked' track call.
	 *
	 * @param {CtaClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ctaClicked,
	/**
	 * Fires a 'Cart Viewed' track call.
	 *
	 * @param {CartViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cartViewed,
	/**
	 * Fires a 'Checkout Started' track call.
	 *
	 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStarted,
	/**
	 * Fires a 'Checkout Step Completed' track call.
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepCompleted,
	/**
	 * Coupon was applied on a user’s shopping cart or order
	
	 *
	 * @param {CouponApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponApplied,
	/**
	 * Coupon was denied from a user’s shopping cart or order
	
	 *
	 * @param {CouponDenied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponDenied,
	/**
	 * User entered a coupon on a shopping cart or order
	
	 *
	 * @param {CouponEntered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponEntered,
	/**
	 * User removed a coupon from a cart or order
	
	 *
	 * @param {CouponRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponRemoved,
	/**
	 * User started a customization
	
	 *
	 * @param {CustomizationInitialized} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationInitialized,
	/**
	 * Tracks the action of items (image, text, shape or QR Code) is removed within the design editor. It maps to the event fired by Customer Canvas Platform
	 *
	 * @param {CustomizationItemAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationItemAdded,
	/**
	 * Tracks the action of items (image, text, shape or QR Code) is removed within the design editor. It maps to the event fired by Customer Canvas Platform
	 *
	 * @param {CustomizationItemChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationItemChanged,
	/**
	 * Tracks the action of items (image, text, shape or QR Code) is removed within the design editor. It maps to the event fired by Customer Canvas Platform
	 *
	 * @param {CustomizationItemRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationItemRemoved,
	/**
	 * User saved and completed the current customization
	 *
	 * @param {CustomizationReviewInitialized} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationReviewInitialized,
	/**
	 * Fires a 'Draft Created' track call.
	 *
	 * @param {DraftCreated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	draftCreated,
	/**
	 * Fires a 'Draft Deleted' track call.
	 *
	 * @param {DraftDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	draftDeleted,
	/**
	 * Fires a 'Draft List Viewed' track call.
	 *
	 * @param {DraftListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	draftListViewed,
	/**
	 * Fires a 'Draft Updated' track call.
	 *
	 * @param {DraftUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	draftUpdated,
	/**
	 * Fires a 'Footer Interaction' track call.
	 *
	 * @param {FooterInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	footerInteraction,
	/**
	 * Fires a 'Information Entered' track call.
	 *
	 * @param {InformationEntered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	informationEntered,
	/**
	 * Fires a 'Log In' track call.
	 *
	 * @param {LogIn} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	logIn,
	/**
	 * Fires a 'Log In Form Displayed' track call.
	 *
	 * @param {LogInFormDisplayed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	logInFormDisplayed,
	/**
	 * Fires a 'Menu Interaction' track call.
	 *
	 * @param {MenuInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteraction,
	/**
	 * Fires a 'New Registration' track call.
	 *
	 * @param {NewRegistration} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newRegistration,
	/**
	 * Fires a 'Order Completed' track call.
	 *
	 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCompleted,
	/**
	 * Fires a 'Page Viewed' track call.
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * User added a product to their cart
	 *
	 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productAdded,
	/**
	 * User clicked through to a product page
	 *
	 * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productClicked,
	/**
	 * User updated configuration of product on product detail page
	 *
	 * @param {ProductConfigured} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productConfigured,
	/**
	 * User filtered a product list or category
	 *
	 * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListFiltered,
	/**
	 * User filtered a product list or category
	 *
	 * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListViewed,
	/**
	 * Product is moved from Cart to Drafts
	 *
	 * @param {ProductMoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productMoved,
	/**
	 * User clicked a product
	 *
	 * @param {ProductQuicklooked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productQuicklooked,
	/**
	 * Product is removed from Cart by clicking "Remove" link located in the Cart
	 *
	 * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productRemoved,
	/**
	 * User views a product
	 *
	 * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productViewed,
	/**
	 * User searched for products
	 *
	 * @param {ProductsSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsSearched,
	/**
	 * User clicked on promotion (banner) or a corresponding CTA
	 *
	 * @param {PromotionClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	promotionClicked,
	/**
	 * User viewed promotion
	 *
	 * @param {PromotionViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	promotionViewed,
	/**
	 * Fires a 'Registration Form Displayed' track call.
	 *
	 * @param {RegistrationFormDisplayed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	registrationFormDisplayed,
	/**
	 * Fires a 'Search Initialized' track call.
	 *
	 * @param {SearchInitialized} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchInitialized,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/xogrp/protocols/tracking-plans/rs_2l3dxlvAHBSOEvgABQ6e9jk9aov`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
